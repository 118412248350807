import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {VerifyWithOtpComponent} from "../../../../shared/components/verify-with-otp/verify-with-otp.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {SnackbarService} from "../../../../shared/services/snackbar.service";
import {UpdatePasswordService} from '../service/update-password.service';


@Component({
  selector: 'app-verify-update-password-otp',
  templateUrl: './verify-update-password-otp.component.html',
  styleUrls: ['./verify-update-password-otp.component.scss'],
})
export class VerifyUpdatePasswordOtpComponent {
  public errMessage: string | null;

  @ViewChild('verifyOtpComponent') verifyOtpComponent!: VerifyWithOtpComponent;


  constructor(
    private router: Router,
    private service: UpdatePasswordService,
    public loader: LoadingService,
    private context: CurrentContextService,
    public dateUtils: DateUtilsService,
    private snackbar: SnackbarService,
  ) {
    this.errMessage = null;
  }
  userEmail() {
    return this.service.context().email!;
  }

  get requestEndpoint() {
    return this.service.baseUrl + '/otp/forgot'
  }

  get requestBody() {
    return { email: this.service.context().email };
  }

  // call the update password service using vars in the context
  onVerify(pin: string) {
    this.service.updateOtp(pin);

    this.errMessage = null;
    lastValueFrom(this.service.updatePassword())
      .then(
        (res) => {
          this.snackbar.openSnackBar('Your password has successfully been updated!');
          this.router.navigate([`${this.context.getCurrentLocationCode()}/login`]);
        },
        (err) => {
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.verifyOtpComponent.resetForm();
              }
              break;
            default:
              console.log(err);
              this.snackbar.openSnackBar('An unexpected error occurred');
          }
        }
      );
  }
}
