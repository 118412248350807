import {Component, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {lastValueFrom} from 'rxjs';
import {UpdatePasswordService} from '../../../../../features/pre-auth/password-reset/service/update-password.service';
import {VerifyWithOtpComponent} from "../../../../../shared/components/verify-with-otp/verify-with-otp.component";
import {SnackbarService} from "../../../../../shared/services/snackbar.service";

@Component({
  selector: 'app-password-change-otp-dialog',
  templateUrl: './password-change-otp-dialog.component.html',
  styleUrls: ['./password-change-otp-dialog.component.scss']
})
export class PasswordChangeOtpDialogComponent {

  @ViewChild('verifyOtpComponent') verifyOtpComponent!: VerifyWithOtpComponent;

  errMessage: string | null = null;

  constructor(public dialogRef: MatDialogRef<PasswordChangeOtpDialogComponent>,
              public updatePasswordService: UpdatePasswordService,
              private snackbar: SnackbarService) {
  }

  get requestEndpoint() {
    return this.updatePasswordService.baseUrl + '/otp/forgot'
  }

  get requestBody() {
    return { email: this.updatePasswordService.context().email };
  }

  onVerify(pin: string) {
    this.updatePasswordService.updateOtp(pin);

    this.errMessage = null;
    lastValueFrom(this.updatePasswordService.updatePassword())
      .then(
        (res) => {
          this.snackbar.openSnackBar('Your password has successfully been updated!');
        },
        (err) => {
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.verifyOtpComponent.resetForm();
              }
              break;
            default:
              console.log(err);
              this.snackbar.openSnackBar('An unexpected error occurred');
          }
        }
      );
  }

  close(): void {
    this.dialogRef.close();
  }
}
