import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {lastValueFrom} from 'rxjs';
import {
  RequestPasswordUpdateOTP
} from '../../../../../features/pre-auth/password-reset/model/request-password-update-otp.model';
import {UpdatePasswordService} from '../../../../../features/pre-auth/password-reset/service/update-password.service';
import {DateUtilsService} from "../../../../../shared/services/dateUtils.service";
import {MatSelectOption} from '../../../../interfaces/mat-select-option.interface';
import {User, UserUpdateRequest} from '../../../../model/user.model';
import {AuthenticationService} from '../../../../services/security/authentication.service';
import {CurrentContextService} from '../../../../services/security/current-context.service';
import {UserService} from '../../../../services/user.service';
import {PasswordChangeOtpDialogComponent} from '../password-change-otp-dialog/password-change-otp-dialog.component';
import {UploadUserProfileImageComponent} from '../upload-user-profile-image/upload-user-profile-image.component';
import {UserProfileDialogInterface} from './user-profile-dialog.interface';


@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {

  selectedProperty: MatSelectOption = {name: '', value: ''};
  passwordResetForm: FormGroup;
  isChangingPassword: boolean = false;
  hideFirstPassword = true;
  hideConfirmPassword = true;
  isSubmitting: boolean = false;
  defaultLogo: string = '../assets/icons/default-nter-logo.svg';
  userProfileFormGroup: FormGroup;
  editingUsername: boolean = false;

  userPhoneNumber: FormControl = new FormControl(null, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'));
  originalPhoneNumber: string | null = null;

  otpPreference: FormControl = new FormControl("EMAIL", Validators.required);
  originalPreference: string | null = "EMAIL";

  constructor(public dialogRef: MatDialogRef<UserProfileDialogComponent>,
              public matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: UserProfileDialogInterface,
              private authService: AuthenticationService,
              private contextService: CurrentContextService,
              private updatePasswordService: UpdatePasswordService,
              private dateUtils: DateUtilsService,
              private userService: UserService) {

    this.passwordResetForm = new FormGroup({
      email: new FormControl(data.user.email, [Validators.required]),
      password: new FormControl(data.user.secret, Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });

    this.userProfileFormGroup = new FormGroup<any>({
      userNames: new FormControl(`${data.user.firstname} ${data.user.lastname}`, [Validators.required])
    });

    if (data.user.contacts) {
      this.setUserMobileContact(data.user);
    }

    if (data.user.otpContactPreference) {
      this.otpPreference.setValue(data.user.otpContactPreference);
      this.originalPreference = data.user.otpContactPreference;
    }

  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }

  close() {
    this.dialogRef.close((this.data));
  }

  openUserSettings(): void {

  }

  logout() {
    this.authService.logout();
    this.close();
  }

  toggleChanged($event: MatSlideToggleChange) {
    // this.notificationService.notificationsPreferenceChanged($event.checked);
  }

  toggleChangePassword(): void {
    this.isChangingPassword = !this.isChangingPassword;
  }

  setUserMobileContact(user: User) {
    const userMobileContacts = user.contacts
      .filter(f => f.active && f.contactType.code == 'MOBILE_PHONE')
      .sort((a, b) => new Date(b.createDate!).getTime() - new Date(a.createDate!).getTime());
    if (userMobileContacts.length > 0) {
      this.userPhoneNumber.setValue(userMobileContacts[0].value);
      this.originalPhoneNumber = userMobileContacts[0].value;
    } else {
      this.otpPreference.disable();
    }
  }

  get phoneNumberChanged() {
    return this.userPhoneNumber.value != this.originalPhoneNumber;
  }

  updatePhoneNumber(): void {
    const request = {
      userId: this.contextService.currentUser.id,
      userEmail: this.contextService.currentUser.email,
      mobileNumber: this.userPhoneNumber.value,
    }
    this.userService.updateUserPhoneNumber(request).subscribe({
      next: (user) => {
        this.contextService.setCurrentUserInContext(user);
        this.setUserMobileContact(user)
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  get preferenceChanged(): boolean {
    return this.otpPreference.value != this.originalPreference;
  }

  updateOtpPreference(): void {
    const request = {
      userId: this.contextService.currentUser.id,
      userEmail: this.contextService.currentUser.email,
      otpContactPreference: this.otpPreference.value,
    }
    this.userService.updateOtpContactPreference(request).subscribe({
      next: (user) => {
        this.contextService.setCurrentUserInContext(user);
        this.originalPreference = user.otpContactPreference;
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  submit(): void {
    if (this.updatePasswordService.passwordInvalid(this.passwordResetForm)) {
      return;
    }
    this.isSubmitting = true;
    const secret: string = this.passwordResetForm.get('password')!.value;
    const confirmSecret: string = this.passwordResetForm.get('confirmPassword')!.value;
    this.updatePasswordService.newPasswordRestContext(secret, confirmSecret, this.data.user.email, this.data.user.id);
    const otpRequest: RequestPasswordUpdateOTP = new RequestPasswordUpdateOTP({
      email: this.data.user.email
    });
    lastValueFrom(this.updatePasswordService.requestForgotPasswordOTP(otpRequest))
      .then(() => {
        this.openDialog();
      })
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  openDialog(): void {
    this.matDialog.open(PasswordChangeOtpDialogComponent, {
      panelClass: 'dialog-medium-user-popover',
      backdropClass: 'dialog-backdrop',
      position: {right: '230px', top: '0px'},
    });

  }

  openUploadProfilePicture(): void {
    this.matDialog.open(UploadUserProfileImageComponent, {
      panelClass: 'dialog-large',
      height: '590px',
      backdropClass: 'dialog-backdrop-opaque',
      hasBackdrop: true
    }).afterClosed().subscribe(() => {
      this.data.user = this.contextService.currentUser;
    });
  }

  updateUserProfile(): void {

    this.editingUsername = false;
    let unSplitNames: string = this.userProfileFormGroup.get('userNames')!.value;
    let nameComponents: string[] = unSplitNames.split(' ');
    if (nameComponents.length > 1) {
      let userLastName = nameComponents.at(-1);
      let userFirstName = nameComponents.slice(0, -1).join(' ');

      let request: UserUpdateRequest = {
        firstname: userFirstName,
        lastname: userLastName!,
        id: this.contextService.currentUser.id
      }

      lastValueFrom(this.userService.updateUserDetails(request))
        .then((user: User) => {
          this.contextService.setCurrentUserInContext(user);
          this.data.user = user;
        })
        .catch((error: string) => {
          console.log(error);
        });
    } else {
      this.userProfileFormGroup.get('userNames')?.setErrors({'error': 'first_and_last_req'});
    }
  }

  toggleEditUsername(): void {
    this.editingUsername = !this.editingUsername;
  }
}

