<app-verify-with-otp *ngIf="userEmail()" #verifyOtpComponent
                     [useContextContact]="false"
                     [userContact]="{type: 'email', value: userEmail()}"
                     [errMessage]="errMessage"
                     [requestOtpEndpoint]="requestEndpoint"
                     [requestOtpBody]="requestBody"
                     (otpVerified)="onVerify($event)"
                     pageHeader="Enter your OTP"
                     [displayContact]="false">
</app-verify-with-otp>
