import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {VerifyWithOtpComponent} from "../../../../shared/components/verify-with-otp/verify-with-otp.component";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {ScreenSizeService} from "../../../../shared/services/screen-size.service";
import {SnackbarService} from "../../../../shared/services/snackbar.service";
import {RetailerPaymentMethodsService} from '../../service/payments.service';

@Component({
  selector: 'app-verify-banking-details',
  templateUrl: './verify-banking-details.component.html',
  styleUrls: ['./verify-banking-details.component.scss']
})
export class VerifyBankingDetailsComponent {
  @ViewChild('verifyOtpComponent') verifyOtpComponent!: VerifyWithOtpComponent;

  public errMessage: string | null = null;

  constructor(
    private snackbar: SnackbarService,
    private router: Router,
    private context: CurrentContextService,
    private paymentService: RetailerPaymentMethodsService,
    public loader: LoadingService,
    public screenSize: ScreenSizeService,
    public dateUtils: DateUtilsService
  ) {
  }

  get requestEndpoint(): string {
    const retailerId = this.context.getCurrentRetailer().id;
    return this.paymentService.baseUrl + `/portal/retailer/${retailerId}/payment-methods/create-request`
  }

  get requestBody() {
    return null;
  }

  public onVerify(pin: string): void {
    if (pin) {
      lastValueFrom(this.paymentService.paymentMethodOtpConfirm(pin))
        .then(() => {
          this.router.navigate(['settings/billing'])
            .catch((err: string) => {
              console.log(err);
            });
        }, err => {
          this.handleError(err);
        });
    }
  }

  private handleError(err: any): void {
    switch (err.status) {
      case 400:
        if ('ValidationException' === err.headers.get('error_code')) {
          this.errMessage = '' + err.headers.get('error_message');
          if (this.errMessage.includes('400 : [ValidationException: ')) {
            let newMessage = this.errMessage.replace('400 : [ValidationException:', '');
            newMessage = newMessage.replace(']', '');
            this.errMessage = newMessage;
            this.verifyOtpComponent.resetForm();
          }
        }
        break;
      default:
        this.snackbar.openSnackBar(`An unexpected error occurred`)
    }
  }
}
