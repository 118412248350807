import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {GoogleMapsModule} from "@angular/google-maps";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterOutlet} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgOtpInputModule} from 'ng-otp-input';
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {NgChartsModule} from 'ng2-charts';
import {ImageCropperModule} from 'ngx-image-cropper';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterLinksComponent} from './core/components/footer/footer-links/footer-links.component';
import {PrivacyPolicyComponent} from './core/components/footer/privacy-policy/privacy-policy.component';
import {
  TermsAndConditionsComponent
} from './core/components/footer/terms-and-conditions/terms-and-conditions.component';
import {HomeScreenComponent} from './core/components/home-screen-component/home-screen.component';
import {PageHeaderComponent} from './core/components/page-header/page-header.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {
  PasswordChangeOtpDialogComponent
} from './core/components/user-profile-popover/dialogs/password-change-otp-dialog/password-change-otp-dialog.component';
import {
  UploadUserProfileImageComponent
} from './core/components/user-profile-popover/dialogs/upload-user-profile-image/upload-user-profile-image.component';
import {
  UserProfileDialogComponent
} from './core/components/user-profile-popover/dialogs/user-profile-dialog/user-profile-dialog.component';
import {UserProfilePopoverComponent} from './core/components/user-profile-popover/user-profile-popover.component';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';
import {NetworkInterceptor} from "./core/services/network-interceptor.service";
import {TokenInterceptor} from './core/services/security/token-interceptor.service';
import {UnauthenticatedAccessInterceptor} from './core/services/security/unauthenticated-access-interceptor.service';
import {PayoutSummaryComponent} from "./features/accounts/payouts/payout-summary/payout-summary.component";
import {PayoutsComponent} from "./features/accounts/payouts/payouts.component";
import {
  TransactionScheduleComponent
} from "./features/accounts/transactions/transaction-schedule/transaction-schedule.component";
import {
  TransactionSummaryComponent
} from "./features/accounts/transactions/transaction-summary/transaction-summary.component";
import {TransactionsComponent} from "./features/accounts/transactions/transactions.component";
import {
  ApplicationFormLoginComponent
} from "./features/applications/application-form-auth/application-form-login/application-form-login.component";
import {
  ApplicationSignupRetailerComponent
} from './features/applications/application-form-auth/application-signup-retailer/application-signup-retailer.component';
import {
  ApplicationSignupUserComponent
} from './features/applications/application-form-auth/application-signup-user/application-signup-user.component';
import {
  ApplicationFormRowLabelComponent
} from './features/applications/application-form/application-form-row-label/application-form-row-label.component';
import {
  ApplicationFormRowComponent
} from './features/applications/application-form/application-form-row/application-form-row.component';
import {
  OfferingApplicationFormComponent
} from './features/applications/application-form/offering-application-form/offering-application-form.component';
import {NewApplicationPageComponent} from './features/applications/new-application-page/new-application-page.component';
import {
  DashboardsBestStatsComponent
} from "./features/dashboards/common/dashboards-best-stats/dashboards-best-stats.component";
import {
  DashboardsChartSectionComponent
} from "./features/dashboards/common/dashboards-chart-section/dashboards-chart-section.component";
import {
  DashboardsInfoDialogComponent
} from "./features/dashboards/common/dashboards-info-dialog/dashboards-info-dialog.component";
import {
  DashboardsStatCardComponent
} from "./features/dashboards/common/dashboards-stat-card/dashboards-stat-card.component";
import {
  DashboardsRetailerReportComponent
} from "./features/dashboards/dashboards-retailer-report/dashboards-retailer-report.component";
import {ApplicationsComponent} from './features/forms/applications/applications.component';
import {TurnoverCaptureComponent} from "./features/forms/turnover-capture/turnover-capture.component";
import {CurrentLeaseComponent} from "./features/leases/current-lease/current-lease.component";
import {LeaseDetailsComponent} from "./features/leases/current-lease/lease-details/lease-details.component";
import {LeaseDocumentsComponent} from "./features/leases/current-lease/lease-documents/lease-documents.component";
import {
  LeaseRatesAndChargesComponent
} from "./features/leases/current-lease/lease-rates-and-charges/lease-rates-and-charges.component";
import {LeaseSpacesComponent} from "./features/leases/current-lease/lease-spaces/lease-spaces.component";
import {LeasingHistoryComponent} from "./features/leases/leasing-history/leasing-history.component";
import {LeasingScheduleComponent} from "./features/leases/leasing-history/leasing-schedule/leasing-schedule.component";
import {LoginComponent} from './features/pre-auth/login/login.component';
import {CheckYourEmailComponent} from './features/pre-auth/password-reset/check-your-email/check-your-email.component';
import {ForgotPasswordComponent} from './features/pre-auth/password-reset/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './features/pre-auth/password-reset/password-reset/password-reset.component';
import {
  VerifyUpdatePasswordOtpComponent
} from './features/pre-auth/password-reset/verify-update-password-otp/verify-update-password-otp.component';
import {PublicRetailSpacesComponent} from './features/pre-auth/public-retail-spaces/public-retail-spaces.component';
import {RegisterUserComponent} from './features/pre-auth/register-user/register-user.component';
import {
  RegistrationRetailerBrandComponent
} from './features/pre-auth/retailer-registration/register-retailer-brand/registration-retailer-brand.component';
import {
  RetailerAwaitCurationComponent
} from './features/pre-auth/retailer-registration/retailer-await-curation/retailer-await-curation.component';
import {VerifyAccountComponent} from './features/pre-auth/verify-account/verify-account.component';
import {
  BankingDetailsComponent
} from './features/settings/settings-billing-and-banking/banking-details/banking-details.component';
import {
  BillingDetailsComponent
} from './features/settings/settings-billing-and-banking/billing-details/billing-details.component';
import {
  SettingsBillingAndBankingComponent
} from './features/settings/settings-billing-and-banking/settings-billing-and-banking.component';
import {
  VerifyBankingDetailsComponent
} from './features/settings/settings-billing-and-banking/verify-banking-details/verify-banking-details.component';
import {
  RetailerContactsComponent
} from './features/settings/settings-brand-information/retailer-contacts/retailer-contacts.component';
import {
  SettingsBrandInformationComponent
} from './features/settings/settings-brand-information/settings-brand-information.component';
import {SocialMediaComponent} from './features/settings/settings-brand-information/social-media/social-media.component';
import {SettingsUsersComponent} from './features/settings/settings-users/settings-users.component';
import {CustomerSupportComponent} from "./features/support/customer-support/customer-support.component";
import {PosHelpCenterComponent} from "./features/support/pos-help-center/pos-help-center.component";
import {MaterialModule} from './material.module';
import {ConfirmRemoveComponent} from './shared/components/confirm-remove/confirm-remove.component';
import {CustomDateFilterComponent} from "./shared/components/custom-date-filter/custom-date-filter.component";
import {CustomRangeSelectorComponent} from "./shared/components/custom-range-selector/custom-range-selector.component";
import {ErrorPopupComponent} from './shared/components/dialogs/error-popup/error-popup.component';
import {FilterPopupComponent} from './shared/components/dialogs/filter-popup/filter-popup.component';
import {IconPopupComponent} from './shared/components/dialogs/icon-popup/icon-popup.component';
import {InfoPanelComponent} from "./shared/components/info-panel/info-panel.component";
import {OfferingCarousel} from './shared/components/offering-carousel/offering-carousel.component';
import {
  OfferingListingComponent
} from './shared/components/offering-carousel/offering-listing/offering-listing.component';
import {SalesReportComponent} from './features/accounts/sales-report/sales-report.component';
import {VerifyWithOtpComponent} from "./shared/components/verify-with-otp/verify-with-otp.component";


@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ApplicationFormLoginComponent,
    ApplicationFormRowComponent,
    ApplicationFormRowLabelComponent,
    ApplicationSignupRetailerComponent,
    ApplicationSignupUserComponent,
    ApplicationsComponent,
    BankingDetailsComponent,
    BillingDetailsComponent,
    CheckYourEmailComponent,
    ConfirmRemoveComponent,
    CurrentLeaseComponent,
    CustomDateFilterComponent,
    CustomRangeSelectorComponent,
    CustomerSupportComponent,
    DashboardsBestStatsComponent,
    DashboardsChartSectionComponent,
    DashboardsInfoDialogComponent,
    DashboardsRetailerReportComponent,
    DashboardsStatCardComponent,
    ErrorPopupComponent,
    FilterPopupComponent,
    FooterLinksComponent,
    ForgotPasswordComponent,
    HomeScreenComponent,
    IconPopupComponent,
    InfoPanelComponent,
    LeaseDetailsComponent,
    LeaseDocumentsComponent,
    LeaseRatesAndChargesComponent,
    LeaseSpacesComponent,
    LeasingHistoryComponent,
    LeasingScheduleComponent,
    LoginComponent,
    NewApplicationPageComponent,
    OfferingApplicationFormComponent,
    OfferingCarousel,
    OfferingListingComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    PasswordChangeOtpDialogComponent,
    PayoutSummaryComponent,
    PayoutsComponent,
    PosHelpCenterComponent,
    PrivacyPolicyComponent,
    PublicRetailSpacesComponent,
    RegisterUserComponent,
    RegistrationRetailerBrandComponent,
    ResetPasswordComponent,
    RetailerAwaitCurationComponent,
    RetailerContactsComponent,
    SalesReportComponent,
    SettingsBillingAndBankingComponent,
    SettingsBrandInformationComponent,
    SettingsUsersComponent,
    SocialMediaComponent,
    TermsAndConditionsComponent,
    TransactionScheduleComponent,
    TransactionSummaryComponent,
    TransactionsComponent,
    TurnoverCaptureComponent,
    UploadUserProfileImageComponent,
    UserProfileDialogComponent,
    UserProfilePopoverComponent,
    VerifyAccountComponent,
    VerifyBankingDetailsComponent,
    VerifyUpdatePasswordOtpComponent,
    VerifyWithOtpComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    ImageCropperModule,
    MatGoogleMapsAutocompleteModule.forRoot(environment.googleMapsApiKey),
    MaterialModule,
    NgChartsModule,
    NgOtpInputModule,
    NgbModule,
    RouterOutlet,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedAccessInterceptor,
      multi: true,
    },
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    }
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
