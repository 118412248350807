import {Injectable} from '@angular/core';

import {SessionKey} from './session-key.enum';
import {Lease} from '../../model/lease.model';
import {Retailer} from '../../model/retailer.model';
import {User} from '../../model/user.model';
import {UserGrantedOfferings} from '../../model/user-features-response.interface';
import {LocationOffering, LocationOfferingFilterRequest} from '../../model/location-offering.interface';
import {JwtHelperService} from '../../../shared/services/jwt-helper.service';
import {AuthTokenInterface} from '../../interfaces/auth-token.interface';
import {BehaviorSubject, lastValueFrom, Observable, Subject} from 'rxjs';
import {LandlordsService} from '../landlords.service';
import {FlattenedPortalUserFeatures, PortalUserAccessMap} from '../../model/user-access.model';
import {LandlordLocationModel} from '../../model/landlord-location.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentContextService {

  private currentUserInternal: User | null = null;
  private currentRetailerInternal: Retailer | null = null;
  private currentLeaseInternal: Lease | null = null;
  private clearCurrentOfferingSubject: Subject<boolean> = new Subject<boolean>();
  private currentOfferingChangeSubject: Subject<LocationOffering> = new Subject<LocationOffering>();
  private offeringSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private jwtHelper: JwtHelperService,
              private landlordService: LandlordsService) {
  }

  get currentUser(): User {
    if (!this.currentUserInternal) {
      this.currentUserInternal = JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_USER_KEY)!);
    }
    this.validateSession();
    return this.currentUserInternal!;
  }

  get currentUserPreferredContact(): {type: string, value: string} {
    if (this.currentUser) {
      switch (this.currentUser.otpContactPreference) {
        case 'EMAIL': return {type: 'email', value: this.currentUser.email};
        case 'MOBILE_PHONE': {
          const userContact = this.currentUser.contacts
            .filter(f => f.contactType.code == 'MOBILE_PHONE' && f.active)
            .sort((a, b) => new Date(b.createDate!).getTime() - new Date(a.createDate!).getTime());
          if (userContact.length > 0) {
            return {type: 'sms', value: userContact[0].value};
          }
        }
      }
    }
    return {type: 'email', value: this.currentUser.email};
  }

  get currentLease(): Lease {
    if (!this.currentLeaseInternal) {
      this.currentLeaseInternal = JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_LEASE_KEY)!);
    }
    this.validateSession();
    return this.currentLeaseInternal!;
  }

  get currentRetailer(): Retailer {
    if (!this.currentRetailerInternal) {
      this.currentRetailerInternal = JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_RETAILER_KEY)!);
    }

    this.validateSession();
    return this.currentRetailerInternal!;
  }
  public reset(resetSession?: boolean): void {
    this.currentUserInternal = null;
    this.currentRetailerInternal = null;
    this.currentLeaseInternal = null;
    this.clearCurrentOfferingSubject.next(true);
    this.offeringSet.next(false);
    if (resetSession) {
      sessionStorage.removeItem(SessionKey.CURRENT_USER_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_RETAILER_KEY);
      sessionStorage.removeItem(SessionKey.USER_GRANTS_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_LEASE_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_OFFERING_KEY);
      sessionStorage.removeItem(SessionKey.CURRENT_LANDLORD_LOCATION);
      sessionStorage.removeItem(SessionKey.FLATTENED_USER_GRANTS);
    }
  }

  public setKey(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  public setCurrentUserInContext(user: User): void {
    sessionStorage.setItem(SessionKey.CURRENT_USER_KEY, JSON.stringify(user));
    this.currentUserInternal = user;
  }

  public setCurrentRetailerInContext(retailer: Retailer): void {
    if (retailer.retailerNew === undefined) {
      retailer.retailerNew = null;
    }
    sessionStorage.setItem(SessionKey.CURRENT_RETAILER_KEY, JSON.stringify(retailer));
    this.currentRetailerInternal = retailer;
  }

  public getCurrentRetailer(): Retailer {
    return this.currentRetailer;
  }

  public isRetailerSet(): boolean {
    return (this.currentRetailerInternal !== null && this.currentRetailerInternal !== undefined);
  }

  public updateRetailerSession(): void {
    sessionStorage.setItem(SessionKey.CURRENT_RETAILER_KEY, JSON.stringify(this.currentRetailerInternal));
  }

  public getRetailerLogoPic(): string | null {
    // check if retailer exists
    if (this.currentRetailer) {
      // check if picture has been added
      if (this.currentRetailer.logoPicUrl) {
        return this.decodeURI(this.currentRetailer.logoPicUrl);
      }
    }
    return null;
  }

  public setRetailerNew(value: boolean): void {
    this.currentRetailerInternal!.retailerNew = value;
    this.updateRetailerSession();
  }

  public isRetailerCurated(): boolean {
    return this.currentRetailer?.curated === 'APPROVED';

  }

  public decodeURI(url: string): string {
    if (!url.startsWith('https')) {
      url = url.replace('http', 'https');
    }
    if (url.includes('ips-dev')) {
      url = url.replace('ips-dev', 'ips.api.dev.labs.sokodistrict.co.za');
    } else if (url.includes('ips-test')) {
      url = url.replace('ips-test', 'ips.api.test.labs.sokodistrict.co.za');
    } else if (url.includes('ips-test')) {
      url = url.replace('ips-prod', 'ips.api.prod.labs.sokodistrict.co.za');
    }
    return url;
  }

  setCurrentUserGrants(userGrantsResponse: PortalUserAccessMap) {
    sessionStorage.setItem(SessionKey.USER_GRANTS_KEY, JSON.stringify(userGrantsResponse));
    this.setFlattenedUserGrants(userGrantsResponse);
  }

  getCurrentUserGrants(): PortalUserAccessMap | null {
    let sessionItem: string | null = sessionStorage.getItem(SessionKey.USER_GRANTS_KEY);
    if (sessionItem == null) {
      return null;
    }
    return JSON.parse(sessionItem) as PortalUserAccessMap;
  }

  setCurrentOffering(location: LocationOffering) {
    sessionStorage.setItem(SessionKey.CURRENT_OFFERING_KEY, JSON.stringify(location));
    this.setCurrentLocationCode(location.locationCode);
    this.sendOfferingChangeEvent(location);
    this.offeringSet.next(true);
  }

  setCurrentOfferingByCode(locationCode: string, offeringCode: string): Promise<void> {
    let currentOffering: LocationOffering | null = this.getCurrentOffering();
    if (!currentOffering || currentOffering.offeringCode != offeringCode) {
      return lastValueFrom(this.landlordService.getOfferingByCode(locationCode, offeringCode))
        .then((res: LocationOffering) => {
          sessionStorage.setItem(SessionKey.CURRENT_OFFERING_KEY, JSON.stringify(res));
          this.sendOfferingChangeEvent(res);
          this.offeringSet.next(true);
        })
        .catch((error: string) => {
          this.sendClearCurrentOfferingEvent();
          console.log(error);
        });
    }
    return Promise.resolve();
  }

  getCurrentOffering(): LocationOffering | null {
    if (sessionStorage.getItem(SessionKey.CURRENT_OFFERING_KEY) != null) {
      return JSON.parse(sessionStorage.getItem(SessionKey.CURRENT_OFFERING_KEY)!) as LocationOffering;
    }
    return null;
  }

  sendOfferingChangeEvent(offering: LocationOffering): void {
    this.currentOfferingChangeSubject.next(offering);
  }

  getOfferingChangeEvent(): Observable<LocationOffering> {
    return this.currentOfferingChangeSubject.asObservable();
  }

  getAccessibleOfferingsFromToken(): string[] {
    if (sessionStorage.getItem(SessionKey.TOKEN_KEY) != null) {
      let token: AuthTokenInterface = this.jwtHelper.decodeToken(sessionStorage.getItem(SessionKey.TOKEN_KEY)!) as AuthTokenInterface;
      return token['offering-ids'];
    }
    return [];
  }

  sendClearCurrentOfferingEvent(): void {
    sessionStorage.removeItem(SessionKey.CURRENT_OFFERING_KEY);
    this.offeringSet.next(false);
    this.clearCurrentOfferingSubject.next(true);
  }

  getClearCurrentOfferingEvent(): Observable<boolean> {
    return this.clearCurrentOfferingSubject.asObservable();
  }

  getAccessibleOfferingsAsObject(filter: boolean = false, offeringTypes?: LocationOfferingFilterRequest): Promise<LocationOffering[]> {
    // always filter by locationCode here
    // filter by specific offeringCode if specified
    return lastValueFrom(this.landlordService.getAllOfferings(offeringTypes))
      .then((offerings: LocationOffering[]) => {
        if (filter) {
          return Promise.resolve(offerings.filter(offering => {
            return this.getAccessibleOfferingsFromToken().some(tokenOffering => {
              return tokenOffering.toLowerCase() === offering.offeringUuid.toLowerCase()
            });
          }));
        }
        return Promise.resolve(offerings);
      });
  }

  private validateSession(): boolean {
    const token = sessionStorage.getItem(SessionKey.TOKEN_KEY);
    const expiration = sessionStorage.getItem(SessionKey.TOKEN_EXPIRY_KEY);

    if (!token && !expiration) {
      return true;
    }

    const expirationDate = new Date(expiration!);
    const valid = token !== null && (expirationDate && expirationDate > new Date());

    if (!valid) {
      this.reset(true);
    }

    return valid;
  }

  clearCurrentOffering(): void {
    sessionStorage.removeItem(SessionKey.CURRENT_OFFERING_KEY);
  }

  isOfferingSet(): Observable<boolean> {
    this.offeringSet.next((this.getCurrentOffering() != null));
    return this.offeringSet.asObservable();
  }

  isOfferingSetSynchronus(): boolean {
    let currentOffering: LocationOffering | null = this.getCurrentOffering();
    return currentOffering != null;
  }

  setCurrentLocationCode(locationCode: string): Promise<void> {
    let currentLandlordLocation: LandlordLocationModel | null = this.getCurrentLandlordLocation();
    if (!currentLandlordLocation || currentLandlordLocation.locationCode != locationCode) {
      return lastValueFrom(this.landlordService.getLandlordLocationByCode(locationCode))
        .then((res: LandlordLocationModel) => {
          sessionStorage.setItem(SessionKey.CURRENT_LANDLORD_LOCATION, JSON.stringify(res));
        })
        .catch((error: string) => {
          sessionStorage.removeItem(SessionKey.CURRENT_LANDLORD_LOCATION);
          console.log(error);
        });
    }
    return Promise.resolve();
  }

  isCurrentLocationSet(): boolean {
    return this.getCurrentLandlordLocation() != null;
  }

  getCurrentLocationCode(): string | null {
    let currentLandlordLocation: LandlordLocationModel | null = this.getCurrentLandlordLocation();
    if (currentLandlordLocation == null) {
      return null;
    }
    return currentLandlordLocation.locationCode;
  }

  getCurrentLandlordLocation(): LandlordLocationModel | null {
    let sessionItem: string | null = sessionStorage.getItem(SessionKey.CURRENT_LANDLORD_LOCATION);
    if (sessionItem == null) {
      return null;
    }
    return JSON.parse(sessionItem) as LandlordLocationModel;

  }

  private setFlattenedUserGrants(currentUserGrants: PortalUserAccessMap): void {
    const featuresMap: Map<string, string[]> = new Map();

    let itemsMap: Map<string, UserGrantedOfferings[]> = new Map();
    itemsMap = {...currentUserGrants.userAccessMap};

    for (let offeringUuid of Object.keys(itemsMap)) {
      let userGrantedOfferings: UserGrantedOfferings[] = [];
      // @ts-ignore
      userGrantedOfferings = Object.assign(userGrantedOfferings, itemsMap[offeringUuid]);
      for (const userGrantedOffering of userGrantedOfferings) {
        for (const userGrantModule of userGrantedOffering.userGrantedModules) {
          for (const userGrantedRole of userGrantModule.userGrantedRoles) {
            for (const userGrantedFeature of userGrantedRole.userGrantedFeatures) {
              const featureCode = userGrantedFeature.featureCode;
              if (!featuresMap.has(offeringUuid)) {
                featuresMap.set(offeringUuid, new Array<string>());
              }
              const locationFeatures = featuresMap.get(offeringUuid)!;
              if (!locationFeatures.includes(featureCode)) {
                locationFeatures.push(featureCode);
              }
            }
          }
        }
      }
    }

    if (featuresMap.size > 0) {
      sessionStorage.setItem(SessionKey.FLATTENED_USER_GRANTS, JSON.stringify(Array.from(featuresMap.entries())));
    }
  }

  public getFlattenedUserGrants(): FlattenedPortalUserFeatures | null {
    let currentUserGrants: PortalUserAccessMap | null = this.getCurrentUserGrants();
    let sessionItem: string | null = sessionStorage.getItem(SessionKey.FLATTENED_USER_GRANTS);

    if (!sessionItem && !currentUserGrants) {
      return null;
    } else if (currentUserGrants && !sessionItem) {
      this.setFlattenedUserGrants(currentUserGrants);
      let map: Map<string, string[]> = new Map(JSON.parse(sessionStorage.getItem(SessionKey.FLATTENED_USER_GRANTS)!)) as Map<string, string[]>;
      return {features: map};
    }
    let map: Map<string, string[]> = new Map(JSON.parse(sessionStorage.getItem(SessionKey.FLATTENED_USER_GRANTS)!)) as Map<string, string[]>;
    return {features: map};
  }
}
