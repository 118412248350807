import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {RequestPasswordUpdateOTP} from '../model/request-password-update-otp.model';
import {UpdatePasswordService} from '../service/update-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public loading = false;
  public errorMsg = null;

  forgotPasswordForm = new FormGroup({
    email: new FormControl(null, {
      validators: [Validators.required, Validators.email]
    })
  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private updatePasswordService: UpdatePasswordService,
              public loader: LoadingService,
              private context: CurrentContextService) {
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      const otpRequest: RequestPasswordUpdateOTP = new RequestPasswordUpdateOTP({
        email: this.email!.value
      });

      this.updatePasswordService.setOtpRequest(this.forgotPasswordForm.value);
      this.loading = true;

      this.updatePasswordService.setOtpContext(otpRequest);
      this.router.navigate([`/forgot-password/password-reset`])
        .catch((reason: string) => {
          console.log(reason)
        });
    } else {
      this.forgotPasswordForm.get('email')?.setErrors({'pattern': true});
    }
  }

  goToLoginPage() {
    this.router.navigate([`${this.context.getCurrentLocationCode()}/login`])
      .catch((error: string) => {
        console.log(error)
      })
  }
}
