import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from "rxjs";
import {VerifyOTPRequest} from "../../../core/model/user.model";
import {AuthenticationService} from "../../../core/services/security/authentication.service";
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {UserService} from "../../../core/services/user.service";
import {VerifyWithOtpComponent} from "../../../shared/components/verify-with-otp/verify-with-otp.component";


@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent {
  @ViewChild('verifyOtpComponent') verifyOtpComponent!: VerifyWithOtpComponent;

  errMessage: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private context: CurrentContextService,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  get requestEndpoint(): string {
    return this.userService.baseUrl + '/' + this.context.currentUser.id.toString() + '/verify/otp/resend';
  }

  get requestBody() {
    return this.context.currentUser.id.toString();
  }

  onVerify(pin: string) {
    const id = this.context.currentUser.id;
    const request: VerifyOTPRequest = {id: id, pin: pin};

    this.errMessage = null;

    lastValueFrom(this.userService.verifyNewUserAccount(request))
      .then(
        (res) => {
          this.authService.setToken(res);
          this.authService.verifyUser();
          const returnUrl = this.route.snapshot.paramMap.get('returnUrl');
          let currentLocationCode = this.context.getCurrentLocationCode();

          let routeTo = `${currentLocationCode}/retailer/brand-info`;
          if (this.context.currentRetailer) routeTo = `/${currentLocationCode}/home`;
          if (returnUrl) routeTo = returnUrl;

          this.router.navigate([routeTo])
            .catch((error: string) => {
              console.log(error)
            });
        },
        (err) => {
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.verifyOtpComponent.resetForm();
              }
              break;
            default:
              console.log(err);
              this.errMessage = 'An unexpected error occurred, please try again';
          }
        }
      );
  }

}
