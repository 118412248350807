<div class="px-3 py-2 pb-3 password-reset-gap-container"
     [class.full-screen-height]="fullscreen"
     fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap="15px">

  <div class="full-width flex-column pre-auth-header-div" fxLayoutAlign="center center">
    <h1 class="pre-auth-header"> {{ pageHeader }} </h1>
    <h2 class="pre-auth-sub-header" style="text-align: center"> {{ pageSubHeader }} </h2>
  </div>

  <div fxFlexOffset="30px"></div>

  @if (captchaControl.invalid) {
    @if (loadingCaptcha) {
      <div>
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    } @else {
      <div>
        <re-captcha [formControl]="captchaControl"
                    [siteKey]="environment.recaptchaApiKey"></re-captcha>
      </div>
    }
  } @else {
    <div class="flex-row">
      <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
        <div class="otp-text" fxLayoutGap="15px">
          <ng-otp-input
            #ngOtpInput
            (onInputChange)="onOtpChange($event)"
            [config]="{
              length: 4,
              allowNumbersOnly: true,
              containerClass: 'otp-wrapper-element',
              inputClass: allowVerify ? 'otp-input-element' : 'otp-input-element-disabled',
              disableAutoFocus: true
            }"
          ></ng-otp-input>

          <mat-error *ngIf="errMessage">{{ errMessage }}</mat-error>
        </div>


        @if (allowVerify) {
          <div fxLayoutGap="20px">
            <button style="width: 200px" (click)="onVerify()" [disabled]="len < 4 || (loader.loading$ | async)"
                    class="button-primary" mat-flat-button>
              <div class="button-text">{{ (loader.loading$ | async) ? 'Working...' : 'Verify' }}</div>
              <mat-icon *ngIf="(loader.loading$ | async)">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </div>

          <div class="p-2 heading-regular" *ngIf="!(loader.loading$ | async) && !disableResend">
            Didn’t receive an OTP?&nbsp;
            <span (click)="resetCaptcha()" class="btn-cursor heading-primary-1">Resend OTP.</span>
          </div>

          <div fxLayout="row" class="p-2 heading-regular" fxLayoutGap="10px"
               *ngIf="(loader.loading$ | async) || disableResend">Didn’t receive an OTP?&nbsp;
            <span class="btn-cursor heading-regular"
                  [matTooltip]="'OTP resend with be allowed at ' + dateUtils.displayDateWithTime(allowResendTime)">Resend OTP.</span>
            <mat-spinner color="primary" diameter="15"></mat-spinner>
          </div>

        } @else {
          <div fxLayoutGap="20px">
            <button style="width: 200px" (click)="onResendOTP(true)"
                    [disabled]="captchaControl.invalid || (loader.loading$ | async)" class="button-primary"
                    mat-flat-button>
              <div class="button-text">{{ (loader.loading$ | async) ? 'Working...' : 'Request OTP' }}</div>
              <mat-icon *ngIf="(loader.loading$ | async)">
                <mat-spinner diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </div>
        }
      </div>
    </div>
  }
</div>

