import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {User} from '../../model/user.model';
import {AuthenticationService} from '../../services/security/authentication.service';
import {CurrentContextService} from '../../services/security/current-context.service';
import {UserProfileDialogComponent} from './dialogs/user-profile-dialog/user-profile-dialog.component';

import {UserProfileDialogInterface} from './dialogs/user-profile-dialog/user-profile-dialog.interface';


@Component({
  selector: 'app-user-profile-popover',
  templateUrl: './user-profile-popover.component.html',
  styleUrls: ['./user-profile-popover.component.scss']
})
export class UserProfilePopoverComponent {

  ownerLogo = "../assets/icons/default-nter-logo.dvg";
  loggedInUser: User | null = null;
  currentRetailerName: string = '';
  userAccessibleTenants: any[] = [];
  isLoggedIn: Observable<boolean>;
  loggedInUserName: string = '';

  constructor(public matDialog: MatDialog,
              private contextService: CurrentContextService,
              private authService: AuthenticationService
  ) {
    this.isLoggedIn = this.authService.isLoggedIn;
    this.isLoggedIn
      .subscribe((isUserLoggedIn: boolean) => {
        this.setLoggedInUser();
        if (this.contextService.getCurrentRetailer()) {
          this.currentRetailerName = this.contextService.getCurrentRetailer().companyName;
        }
      });
  }

  openDialog(): void {
    let dialogData: UserProfileDialogInterface = {
      user: this.loggedInUser!,
      currentRetailer: this.currentRetailerName,
    };
    this.matDialog.open(UserProfileDialogComponent, {
      panelClass: 'dialog-medium-user-popover',
      backdropClass: 'dialog-backdrop',
      position: {right: '10px', top: '0px'},
      data: dialogData,
    }).afterClosed()
      .subscribe((data: UserProfileDialogInterface) => {
        this.setLoggedInUser();
      });
  }

  setLoggedInUser(): void {
    let loggedInUserObject = this.contextService.currentUser;
    if (loggedInUserObject) {
      this.loggedInUser = loggedInUserObject;
      this.loggedInUserName = `${this.loggedInUser.firstname} ${this.loggedInUser.lastname}`
    }
  }

  getLoggedInImage(): string {
    if (this.loggedInUser != null && this.loggedInUser.profileImage !== null && this.loggedInUser.profileImage !== "") {
      return this.loggedInUser.profileImage;
    }
    return '../assets/icons/default-nter-logo.svg';
  }
}
