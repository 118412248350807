<app-verify-with-otp #verifyOtpComponent
                     [fullscreen]="false"
                     [useContextContact]="true"
                     [errMessage]="errMessage"
                     [requestOtpEndpoint]="requestEndpoint"
                     [requestOtpBody]="requestBody"
                     (otpVerified)="onVerify($event)"
                     pageHeader="Verify your account"
                     [displayContact]="false">
</app-verify-with-otp>
