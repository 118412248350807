import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {LoadingService} from "../../../core/services/loading.service";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {DateUtilsService} from "../../services/dateUtils.service";
import {SnackbarService} from "../../services/snackbar.service";

@Component({
  selector: 'app-verify-with-otp',
  standalone: false,
  templateUrl: './verify-with-otp.component.html',
})
export class VerifyWithOtpComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  @Input() pageHeader: string = 'Please verify your email';
  @Input() useContextContact: boolean = true;
  @Input() userContact: {type: string, value: string} = {type: 'email', value: ''};
  @Input() fullscreen: boolean = true;
  @Input() requestOtpEndpoint!: string;
  @Input() requestEndpointType: "get" | "post" = "post";
  @Input() requestOtpBody?: any;
  @Input() errMessage: string | null = null;
  @Input() displayContact: boolean = true;

  @Output() otpVerified = new EventEmitter<string>();

  pageSubHeader?: string;
  otpForm;

  len = 0;

  disableResend = true;
  delayInMinutes = 1;
  allowResendTime = new Date();

  captchaControl: FormControl;
  allowVerify: boolean = false;

  loadingCaptcha = true;

  constructor(
    private fb: FormBuilder,
    public loader: LoadingService,
    public dateUtils: DateUtilsService,
    private snackbar: SnackbarService,
    private http: HttpClient,
    private context: CurrentContextService
  ) {
    this.otpForm = this.resetForm();
    this.captchaControl = new FormControl(null, Validators.required,);
  }

  ngOnInit() {
    if (this.useContextContact) {
      this.userContact = this.context.currentUserPreferredContact;
      console.log(this.userContact)
    }
    this.pageSubHeader = 'Send a One Time Pin to ' + this.formattedUserContact;
    setTimeout(() => this.loadingCaptcha = false, 1500);
  }

  resetCaptcha() {
    this.captchaControl.reset();
    this.otpForm.reset();
    this.errMessage = null;
    this.allowVerify = false;
  }

  onOtpChange(event: any) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  resendDelay() {
    this.disableResend = true;
    const delay = this.delayInMinutes * 60 * 1000;
    this.allowResendTime = new Date(Date.now() + delay);
    setTimeout(() => {
      this.disableResend = false;
    }, delay);
  }

  onResendOTP(showPopUp: boolean = true): void {
    this.errMessage = null;
    this.otpRequest(this.requestOtpEndpoint, this.requestOtpBody).subscribe(
      {
        next: value => {
          this.pageSubHeader = 'We have sent a One Time Pin to ' + this.formattedUserContact;
          this.resendDelay();
          this.allowVerify = true;
          this.errMessage = null;
          this.otpForm.enable();
          // show success message
          if (showPopUp) {
            this.snackbar.openSnackBar(`We have sent a One Time Pin to ${this.formattedUserContact}`)
          }
        },
        error: err => {
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('pin')!.setValue(null);
                this.ngOtpInputRef.setValue(null);
              }
              break;
            default:
              this.snackbar.openSnackBar(`An unexpected error occurred`)
          }
        }
      }
    );
  }

  onVerify() {
    const pin = this.otpForm.value.pin;
    this.otpVerified.emit(pin)
  }

  private otpRequest(endpoint: string, body: any) {
    if (this.requestEndpointType == "get") {
      return this.http.get(endpoint, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      });
    } else {
      return this.http.post(endpoint, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      });
    }
  }

  resetForm(): FormGroup {
    return this.fb.group({
      pin: [{value: null, disabled: true}, Validators.required],
    });
  }

  get formattedUserContact() {
    if (this.displayContact) {
      return this.userContact.type == 'sms' ? '*******' + this.userContact.value.slice(7,10) : this.userContact.value;
    } else {
      return 'your preferred contact';
    }
  }


  protected readonly environment = environment;
}
