import {inject, Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private _snackbar: MatSnackBar = inject(MatSnackBar);

  public openSnackBar(message: string,
                      horizontalPosition: MatSnackBarHorizontalPosition = 'right',
                      verticalPosition: MatSnackBarVerticalPosition = 'top',
                      durationInSeconds = 5 * 1000) {
    if (message?.length > 0) {
      this._snackbar.open(message, 'Dismiss', {
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        duration: durationInSeconds,
      });
    }
  }
}
